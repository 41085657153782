import PageHeader from '@/components/PageHeader'
// import tableMixin from '@/minxin/table-minxin'
import printJS from 'print-js'
const Index = {
  name: 'delivery-fwarehouse',
  components: {
    PageHeader
  },
  mounted() {
    console.log(this.$route.params)
    // if (!this.$route.params.StockOutId) {
    //   return this.$message.error('没有参数')
    // }
    this.$nextTick(() => {
      this.getData()
    })
  },
  // mixins: [tableMixin],
  data() {
    return {
      isAllIn: true,
      Message: {},
      tableData: [],
      total: 0
    }
  },
  methods: {
    async  getData() {
      const response = await this.$api.QueryStockDeliveryOutStockInfo({
        StockOutType: this.$route.params.StockOutType,
        StockOutId: this.$route.params.StockOutId
        // DeliveryId: this.$route.params.DeliveryId, // 'D1000011467',
        // OrderId: this.$route.params.OrderId// '1000006646'
      })
      if (!response) {
        this.isAllIn = false
        return this.$message.error('出错')
      }
      console.log(response)
      this.Message = response.Message
      this.Message.orderStockOutDeatilInfos.map(item => {
        this.total += item.Amount
      })
      this.isAllIn = false
    },
    // 保存物流信息
    saveWuLiu() {
      // return
      console.log('this.Message.stockOutLogisticsInfos', this.Message.stockOutLogisticsInfos)
      if (this.Message.stockOutLogisticsInfos.length <= 0) return this.$message.error('请先选择添加物流')
      const result = this.Message.stockOutLogisticsInfos.filter(item => !item.LogisticsCode || !item.LogisticsName)
      if (result.length > 0) return this.$message.error('请填写完整的物流信息')
      this.$api.EditDeliveryExpress(this.Message.stockOutLogisticsInfos).then(res => {
        if (res.RetCode === '0') return this.$message.success(res.RetMsg)
        return this.$message.error(res.RetMsg)
      })
    },
    addWuLiu() {
      this.Message.stockOutLogisticsInfos.push({
        LogisticsCode: '',
        LogisticsName: '',
        StockOutId: this.Message.orderStockOutInfo.StockOutId
      })
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    // 确认发货
    sureSend() {
      console.log(this.Message.orderStockOutInfo)
      this.$api.EditOrderDeliveryStatus({
        stockOutLogistics: this.Message.stockOutLogisticsInfos,
        'StockOutId': this.Message.orderStockOutInfo.StockOutId,
        'DeliveryId': this.Message.orderStockOutInfo.DeliveryID
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.$message.success(res.RetMsg)
          this.$router.push({
            name: 'delivery-goods'
          })
          return
        }
        this.$message.error(res.RetMsg)
      })
    },
    // 交货单打印跳转
    printA() {
      const form = {
        DeliveryID: this.Message.orderStockOutInfo.DeliveryID, // 交货单ID
        SendCompanyID: this.Message.orderStockOutInfo.SendCompanyID, // 客户ID
        SellCompanyID: this.Message.orderStockOutInfo.SellCompanyID //  公司ID
      }
      const index = this.$store.state.index.cachedViews.findIndex(item => item === 'deliveryNoteReport')
      this.$store.state.index.cachedViews.splice(index, 1)
      this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
      this.$router.push({
        name: 'deliveryNoteReport',
        params: { isStockParams: form }
      })
    },
    //  打印
    printTest() {
      var title = ''
      var divElements = ''
      const top_view_print = document.getElementById('top_view_print').innerHTML // 打印标题内容
      const bottom_view_print_1 = document.getElementById('bottom_view_print_1').innerHTML
      const table_footer = document.getElementById('remake_view').innerHTML
      const top_mid_print = document.getElementById('top_mid_print').innerHTML
      var printWindow = window.open('', '_blank', '')
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      const label = [
        { label: '物料编号', prop: 'InvCode' },
        { label: '原厂编号', prop: 'ManuPartNum' },
        { label: '物料名称', prop: 'InvName' },
        { label: '品牌', prop: 'BrandName' },
        { label: '规格/型号', prop: 'Specification' },
        { label: '单位', prop: 'StockUnitN' },
        { label: '批号', prop: 'LotNum' },
        { label: '生产日期', prop: 'ProductDate' },
        { label: '有效期截止', prop: 'ExpiredDate' },
        { label: '数量', prop: 'OutNum' },
        { label: '含税单价', prop: 'Price' },
        { label: '含税金额', prop: 'Amount' },
        { label: '注册证号', prop: 'RegisterNo' },
        { label: '生产企业许可号', prop: 'ProductLicenseNo' },
        { label: '存储', prop: 'aa' }
      ]
      label.map(item => {
        num += 1
        str_tr += `
           <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
        `
      })
      this.Message.orderStockOutDeatilInfos.map((item, index) => {
        label.map((item1, index1) => {
          str_td += `<td  id="testTd" style="text-align:center;border:solid #e5e5e5 1px;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
        })
      })
      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
      <table border="1" class='table-0 m-tb-20 ' id="dasasasdsad" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:none;width:100%">
      <tr>
        ${str_tr}
     </tr>
       ${str}
    </table>
  `
      divElements = `<div class='div-0'>${top_view_print + top_mid_print + table_ + table_footer}<div style="width: 90%;" class="f12  flex j-between m-top-10">${bottom_view_print_1}</div></div>`
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
       html, body {
         font-size: 12px;
       }
      .p-lr-20 {padding: 0 20px; }
      .m-bottom-20 {margin-bottom: 20px; }
      .weight { font-weight: 700 }
      .f14 { font-size:12px}
      .f20 {font-size:12px}
      .flex {display:flex}
      .j-center {justify-content: center;}
      .column { flex-direction: column;}
      .a-center {align-items: center;}
      .f-warp { flex-wrap: wrap; }
      .j-start {justify-content: flex-start;}
      .m-tb-10 {margin: 10px 0;}
      .m-tb-20 {margin: 20px 0;}
      .j-between {justify-content: space-between;}
      .m-top-10 {margin-top: 10px }
      .element.style {width: auto; }
      .table_info {
        max-width: 1000px;
        margin: 0 auto;
      }
      table{table-layout: fixed;}
      td{word-break: break-all; word-wrap:break-word;}
      .div-0 {page-break-after: always}
      </style>`
      )
      printWindow.document.write('</head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
      window.onload = function() {
        class Test {
          constructor() {
            this.pageIndex = 0 // 当前页
            this.height_print = '' // 初始高度
            this.windowHeight = null // 窗口高度
            this.max_height = null // 最大高度
            this.tbody_tr = '' // 获取表格子集
            this.splice_arr = [] // 被删掉的元素
            this.header_tr = null // 表头的tr 不改动
            this.table_main = null // 表格 父级
            this.top_veiw_html = null // 表头的内容、
            this.bottom_veiw_html = null // 表底的内容
            this.title_view_html = null //  顶部标题dom
            this.table_footer_html_0 = null // 表格合计内容
            this.table_footer_html_1 = null // 表格合计内容
          }
          init() {
             this.windowHeight = window.screen.height
             this.max_height =  Math.round(this.windowHeight  / 2)
             console.log('this.max_height ',this.max_height )
            const t_b_view = document.getElementsByClassName('adsadasd1')
            this.table_main = document.getElementsByClassName('table-0')[ this.pageIndex] // 表格 父级
            this.tbody_tr = this.table_main.childNodes[1].children // 获取表格子集
            this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
            this.title_view_html = document.getElementsByClassName('header')[0].innerHTML // 顶部标题dom
            this.heightA()
            console.log('初始高度', this.height_print)
            this.isSet()
          }
      
          // 是否换页
          isSet() {
            if (this.height_print <= this.max_height) return // 不需要换页
            if (this.height_print > this.max_height) {
               this.setData()
            }
          }
          // 处理换页的数据
          setData() {
            console.log(this.splice_arr)
            const arr = []
            for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
              console.log('this.height_print()', this.height_print)
              if (this.height_print > this.max_height) {
                // 将一个节点存起来
                arr.push(this.tbody_tr[i])
                // 删除一个节点
                this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                this.heightA()
              }
            }
            this.tbody_tr = arr
            this.initDocument()
          }
      
          // 计算高度 当前页高度
          heightA() {
            this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
            console.log('第页高度', this.pageIndex, this.height_print)
            // return this.height_print
          }
      
          // 为页面添加新的页
          initDocument() {
            this.pageIndex += 1
            console.log('被删掉的数组', this.tbody_tr)
            let new_tr_td = ''
            for (let i = 0; i < this.tbody_tr.length; i++) {
              new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
            }
            console.log('new_tr_td', new_tr_td)
      
            // 新的表格
            const table_ = "<table border='1' class='table-0' id='dasasasdsad' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:none;width:100%'>" + this.header_tr + new_tr_td + '</table>'
            const dom_new = "<div class='div-0' style='margin-top: 140px'><div class='header weight f20 flex j-center column a-center m-tb-20'>" + this.title_view_html + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.top_veiw_html + '</div>' + table_ + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_0 + '</div>' + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_1 + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.bottom_veiw_html + '</div> </div> '
            console.log(dom_new)
            //   window.document.write(dom_new)
            const conentDiv = document.createElement('div')
            conentDiv.innerHTML = dom_new
            document.body.appendChild(conentDiv)
            this.init()
          }
        }
          setTimeout(() => {
              const test = new Test()
              test.init()
        }, 60)
      }

      </script>`)
      console.log('divElements', divElements)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    //  打印
    print() {
      const html = document.querySelector('#' + 'printDom').innerHTML
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      // document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children[0].children.re
      // console.log(document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children)
      // 删除最后一项
      const dom_ = document.getElementById('printDOMElement')
      const tr_arr = document.getElementsByClassName('mid_info')
      const header_arr = document.getElementsByClassName('header')
      const remake_view = document.getElementById('remake_view')
      const empty = document.getElementsByClassName('empty')
      // const btn = document.getElementById('btn')
      // btn.parentNode.removeChild(btn)
      dom_.style.padding = '10px'
      for (const i in tr_arr) {
        console.log(tr_arr[i].style)
        if (tr_arr[i].style) {
          tr_arr[i].style.display = 'flex'
          tr_arr[i].style.justifyContent = 'space-between'
          tr_arr[i].style.marginTop = '10px'
        }
      }
      for (const i in empty) {
        console.log(empty[i].style)
        if (empty[i].style) {
          empty[i].style.marginTop = '20px'
        }
      }
      for (const i in header_arr) {
        console.log(tr_arr[i].style)
        if (header_arr[i].style) {
          header_arr[i].style.width = '100%'
          //  header_arr[i].style.width = '' + (1/header_arr.length *100%)
          header_arr[i].style.textAlign = 'center'
          header_arr[i].style.weight = 'blod'
        }
      }
      remake_view.style.display = 'flex'
      remake_view.style.justifyContent = 'space-between'
      remake_view.style.width = '100%'
      remake_view.style.height = '50px'
      remake_view.style.border = '1px solid #EBEEF5'
      remake_view.style.borderTop = 'none'
      const remake_view_left = document.getElementById('remake_view_left')
      const remake_view_right = document.getElementById('remake_view_right')
      // min-border-right flex
      console.log(remake_view_left)
      remake_view_left.style.width = '60%'
      remake_view_left.style.borderRight = '1px solid #EBEEF5' // width:80px;line-height:50px
      remake_view_left.childNodes[0].style.borderRight = '1px solid #EBEEF5'
      remake_view_left.childNodes[0].style.width = '95px'
      remake_view_left.childNodes[0].style.lineHeight = '50px'
      remake_view_left.childNodes[0].style.textAlign = 'center'
      remake_view_right.style.flex = '1'
      remake_view_right.style.display = 'flex'
      remake_view_right.childNodes[0].style.borderRight = '1px solid #EBEEF5'
      remake_view_right.childNodes[0].style.width = '100px'
      remake_view_right.childNodes[0].style.lineHeight = '50px'
      remake_view_right.childNodes[0].style.textAlign = 'center'
      remake_view_right.childNodes[1].style.paddingLeft = '10px'
      // remake_view.childNodes[0].style.borderRight = '1px solid #EBEEF5'
      // remake_view.childNodes[0].childNodes[0].style.borderRight = '1px solid #EBEEF5'
      // remake_view.childNodes[0].style.display = 'flex'
      // remake_view.childNodes[1].style.display = 'flex'
      // remake_view.childNodes[1].style.borderLeft = '1px solid #EBEEF5'
      // 提取第一个表格的内容 即表头
      const ths = div.querySelectorAll('.el-table__header-wrapper th')
      const ThsTextArry = []
      for (let i = 0, len = ths.length; i < len; i++) {
        ThsTextArry.push(ths[i].innerText)
      }
      // 删除多余的表头
      div.querySelector('.hidden-columns').remove()
      // 第一个表格的内容提取出来后已经没用了 删掉
      div.querySelector('.el-table__header-wrapper').remove()

      // 将第一个表格的内容插入到第二个表格
      let newHTML = '<tr style="text-align: center; >'
      for (let i = 0, len = ThsTextArry.length; i < len; i++) {
        newHTML += '<td style="text-align: center; font-weight: bold; ">' + ThsTextArry[i] + '</td>'
      }
      newHTML += '</tr>'
      div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML)
      //  .el-table__body-wrapper table td{ border:1px solid #EBEEF5; text-align: center;};
      // 将新的 DIV 添加到页面 打印后再删掉
      // document.querySelector('body').appendChild(div) .el-table__body-wrapper table{border-collapse:collapse};
      printJS({
        printable: 'printDom',
        type: 'html',
        scanStyles: false,
        style: '.el-table__body-wrapper table{border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table td { border:1px solid #EBEEF5; text-align: center}' // 表格样式
      })
      div.remove()
    }
  }
}

export default Index
