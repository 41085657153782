<template>
  <div class="delivery-fwarehouse">
    <PageHeader title="出库发货" />
    <div v-loading="isAllIn">
      <div class="top_main_info flex j-between p-top-20 p-bottom-10 m-bottom-10 min-border-bottom">
        <div class="left flex j-start a-end column">
          <div class="f14 weight">填写物流信息</div>
          <div class="ModelBttton-white-24 m-top-10 f12" @click="addWuLiu">添加物流</div>
        </div>
        <div class="mid p-lr-20 flex j-between column">
          <div class="flex">
            <div class="FahuoList">
              <label class="Name">发货单位</label>
              <span class="text">{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.SellCompany : '' }}</span>
            </div>
            <div class="FahuoList">
              <label class="Name">发货地址</label>
              <span class="text">{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.DeliveryAddress : '' }}</span>
            </div>
          </div>
          <div class="flex m-top-5">
            <div class="FahuoList">
              <label class="Name">收货单位</label>
              <span class="text">{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.SendCompany :'' }}</span>
            </div>
            <div class="FahuoList">
              <label class="Name">送货地址</label>
              <span class="text">{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.OrderAddress : '' }}</span>
            </div>
          </div>
          <div v-for="(item,index) in Message.stockOutLogisticsInfos" :key="index">
            <div class="FahuoList">
              <label class="Name1">物流公司</label>
              <el-input v-model="item.LogisticsName" class="mid1" />
            </div>
            <div class="FahuoList">
              <label class="Name1">物流单号</label>
              <el-input v-model="item.LogisticsCode" class="mid1" />
            </div>
          </div>
        </div>
        <!--  v-if="Message.orderStockOutInfo && Message.orderStockOutInfo.Status !== 1" -->
        <div class="right flex a-end j-start p-bottom-10" @click="saveWuLiu">
          <el-button size="mini">保存物流</el-button>
        </div>
        <div v-if="Message.orderStockOutInfo && Message.orderStockOutInfo.Status === 3" class="right flex a-end j-start m-right-10 p-bottom-10" @click="sureSend">
          <el-button size="mini" type="primary">确认发货</el-button>
        </div>
      </div>
      <!-- 打印表单 -->
      <div id="printDom" class="table_info m-top-20 p-lr-20">
        <div id="top_view_print">
          <div class="header weight f14 flex j-center">{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.SellCompany : '' }}</div>
          <div class="header weight f14 flex j-center">销售出库单</div>
        </div>
        <div id="top_mid_print">
          <div class="mid_info f12 flex j-between m-top-10">
            <div style="min-width:300px">终端：{{ Message.orderStockOutInfo ? Message.orderStockOutInfo.SendCompany :'' }}</div>
            <div style="min-width:300px">发票号码：</div>
            <div style="min-width:200px">页码：1/1</div>
          </div>
          <div class="mid_info f12 flex j-between m-top-10">
            <div style="min-width:300px">买方：{{ Message.orderStockOutInfo? Message.orderStockOutInfo.BuyCompany :'' }}</div>
            <div style="min-width:300px">出库日期：{{ Message.orderStockOutInfo? Message.orderStockOutInfo.WriteDate:'' }}</div>
            <div style="min-width:200px">币种：CNY</div>
          </div>
          <div class="mid_info f12 flex j-between m-top-10 empty">
            <div style="min-width:300px;" class=" el-1 ">送货地址：{{ Message.orderStockOutInfo? Message.orderStockOutInfo.OrderAddress:'' }}</div>
            <!-- <div style="min-width:300px">采购订单号：{{ Message.orderStockOutInfo? Message.orderStockOutInfo.OrderID:'' }}</div> -->
            <div style="min-width:300px">发货单号：{{ Message.orderStockOutInfo?Message.orderStockOutInfo.StockOutId:'' }}</div>
            <div style="min-width:200px" />
          </div>
        </div>
        <div style="height:20px" />
        <div class="m-tb-20" style="width: 100%">
          <el-table
            :data="Message.orderStockOutDeatilInfos"
            border
            :fit="true"
          >
            <!--  :span-method="arraySpanMethod"-->
            <af-table-column prop="InvCode" label="物料编号" width="100px" align="center" show-overflow-tooltip />
            <af-table-column prop="ManuPartNum" label="原厂编号" align="center" show-overflow-tooltip />
            <af-table-column prop="InvName" label="物料名称" align="center" show-overflow-tooltip />
            <af-table-column prop="BrandName" label="品牌" align="center" show-overflow-tooltip />
            <af-table-column prop="Specification" label="规格/型号" align="center" show-overflow-tooltip />
            <el-table-column prop="StockUnitN" label="单位" align="center" show-overflow-tooltip />
            <!-- <af-table-column prop="BL" label="标类" /> -->
            <af-table-column prop="LotNum" label="批号" align="center" show-overflow-tooltip />
            <af-table-column prop="ProductDate" label="生产日期" align="center" show-overflow-tooltip />
            <af-table-column prop="ExpiredDate" label="有效期截止" align="center" show-overflow-tooltip />
            <af-table-column prop="OutNum" label="数量" align="center" show-overflow-tooltip />
            <af-table-column prop="Price" label="含税单价" align="center" show-overflow-tooltip />
            <af-table-column prop="Amount" label="含税金额" align="center" show-overflow-tooltip />
            <af-table-column prop="RegisterNo" label="注册证号" align="center" show-overflow-tooltip />
            <af-table-column prop="ProductLicenseNo" label="生产企业许可号" align="center" show-overflow-tooltip />
            <af-table-column prop="aa" align="center" label="存储" show-overflow-tooltip />
          </el-table>
          <div id="remake_view" class="remake_view flex j-bewteen table_footer">
            <div id="remake_view_left" class="remake_view_left  f14  min-border-right flex">
              <div style="width:80px;line-height:50px" class="min-border-right center">备注</div>
              <div style="line-height:50px" class="p-left-20">{{ Message.orderStockOutInfo? Message.orderStockOutInfo.Reamrks:'' }}</div>
            </div>
            <div id="remake_view_right" class="remake_view_right f14 flex">
              <div style="width:80px;line-height:50px" class="min-border-right center">合计</div>
              <div style="line-height:50px" class="p-left-20">{{ total }}</div>
            </div>
          </div>
        </div>
        <div id="bottom_view_print_1" class="f12  flex j-between m-top-10">
          <span>制单：</span>
          <span>货物签收：</span>
          <span>发票签收：</span>
          <span>货票签收：</span>
          <span>到货温度：</span>
        </div>
      </div>
      <div id="btn" class="caozuo t-right p-right-20 p-top-20 p-bottom-5 t_line_s m-top-20">
        <el-button @click="() => $router.push({name:'delivery-goods'})">取消</el-button>
        <!--        printTest-->
        <el-button type="primary" icon="el-icon-printer" @click="printA">打印</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Index from '@/minxin/management/delivery-fwarehouse'
export default Index
</script>
<style>
/* .el-table__header {width:100% !important}
.el-table__body {width:100% !important} */
</style>
<style scoped lang="scss">
@import "@/style/search.scss";
.top_main_info{
    width:100%;
    height: auto;
    .left{
        width: 150px;
        .info{
            width: 100px;
            height: 30px;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            line-height: 30px;
        }
    }
    .mid{
        flex: 1;
        height: auto;
        .FahuoList{
          width: 50%;
          box-sizing:border-box;
          padding-right: 20px;
          margin-bottom: 10px;
          display: inline-block;
          .Name{ width:100px; font-size: 14px; float: left; display:inline-block; color: #999; font-weight: bolder; height:auto;line-height:120%;box-sizing:border-box; text-align: right; padding-right: 10px;}
          .text{ width: calc(100% - 100px); display: inline-block; font-size: 14px; float: right; line-height: 120%;word-wrap: break-word;word-break:break-all;}
          .el-input{ width: calc(100% - 100px);}
          .Name1{ width:100px; font-size: 14px; float: left; display:inline-block; color: #999; font-weight: bolder; height:30;line-height:30px;box-sizing:border-box; text-align: right; padding-right: 10px;}
          }
    }
    .right{
         width: auto;padding-right: 10px;
    }
}
.table_info{
    .header{
        width: 100%;
    }
}
.remake_view{
  width: 100%;
  height: 50px;
  border: 1px solid #EBEEF5;
  border-top: none;
  .remake_view_left{
    width: 60%;
  }
  .remake_view_right{
     flex: 1;
  }
}
</style>

